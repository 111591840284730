var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "home.flashshow",
    title: "Flash Show",
    description: "A flash show element for displaying elements in a fast slideshow.",
    editable: true,
    fields: {
        inner: {
           type: "inner",
           title: "Items",
           description: "The elements to display in the slideshow. The first object will appear under every other slide. When a slide is still being downloaded the first slide should remain visible.",
           required: true
        },
        overlay: {
            type: "inner",
            title: "Overlay",
            description: "An overlay to display over the slideshow."
        },
        overlayClass: {
            type: "class",
            title: "Overlay Class",
            description: "The class to apply to the overlay.",
        },
        interval: {
            type: "number",
            title: "Interval",
            description: "The interval between slides in milliseconds.",
            default: 2000,
            addtouae: true
        },
        showPause: {
            type: "boolean",
            title: "Show Pause",
            description: "Whether to show a pause button.",
            default: true
        },
        onlyMobile: {
            type: "boolean",
            title: "Only Mobile",
            description: "Whether to only show on mobile.",
            default: false
        },
        randomize: {
            type: "boolean",
            title: "Randomize",
            description: "Whether to randomize the order of the slides.",
            default: false,
            addtouae: true
        }
    }, 
    passthrough: {
        except: ["inner", "overlay", "overlayClass", "interval"]
    },
    // supportServer: false
});

var events = require("../../../uam/events.js");
// var uai = require("../interface/interface.js");
// var uae = require("../interface/element.js");
var jsonRender = require("../interface/jsonRender.js");
// var generateRandomID = require("../../../uam/functions/generateRandomID.js").function;
var sleep = require("../../../uam/functions/sleep.js").function;
var repeater = require("./ua.repeater.js");

/**
 * The render function for the flashshow element.
 * @param {*} options The jsonObject to render (should use fields)
 */
exports.render = async function (options) {

    var inner = options.inner;

   

    //if inner is a document fragment, split it into an array
    try {

        //if inner is an object and not an array
        if (!(Array.isArray(inner))) {
            //if the inner is an object and has a namespace render it
            if ("namespace" in inner) {

                if (inner.namespace === repeater.namespace) {
                    inner = await repeater.render(inner);
                } else {
                    inner = await jsonRender.render(inner);
                }
                
            } else {
                inner = [inner];
            }
        } 

        if ("nodeType" in inner && inner.nodeType === 11) {
            inner = Array.from(inner.children);
        }
    } catch (error) {
        console.log("Error splitting document fragment", error);
    }

    if ("randomize" in options) {
        //if randomize is true, shuffle the inner array
        if (options.randomize) {
            inner = inner.sort(() => Math.random() - 0.5);
        }
    }

    //for each inner wrap it in a div
    for (var i = 0; i < inner.length; i++) {
        inner[i] = {
            n: "div",
            c: "home-flashshow-item",
            i: inner[i]
        }
    }

    var flashshowMobile = {
        n: "div",
        c: "home-flashshow d-md-none home-flashshow-mobile",
        i: [{
            n: "div",
            c: "home-flashshow-holster",
            i: inner
        }]
    }

    //split inner into half
    var colInner = {
        first: inner,
        second: [...inner.slice(inner.length/2), ...inner.slice(0, inner.length/2)]
    };

    var flashshowDesktop = {
        n: "div",
        c: "home-flashshow d-none d-md-flex home-flashshow-desktop",
        i: [{
            n: "div",
            c: "home-flashshow-holster home-flashshow-holster-col",
            i: colInner.first
        }, {
            n: "div",
            c: "home-flashshow-holster home-flashshow-holster-col",
            i: colInner.second
        }]
    }

    var flashExtra = [];

    if ("overlay" in options) {

        flashExtra.push({
            n: "div",
            c: "home-flashshow-overlay " + (options.overlayClass || ""),
            i: options.overlay
        });

    }

    var flashShowToolBox = [];

    if ("showPause" in options) {
        if (options.showPause) {
            flashShowToolBox.push({
                n: "button",
                c: "btn btn-primary bg-transparent bg-75-primary-hover border-0 text-background text-background-hover rounded home-flashshow-pause",
                i: {
                    n: "i",
                    c: "bi bi-pause-circle"
                }
            });
        }
    }

    if (flashShowToolBox.length > 0) {
        flashExtra.push({
            n: "div",
            c: "home-flashshow-toolbox",
            i: flashShowToolBox
        });
    }

    //append extras
    flashshowMobile.i.push(...flashExtra);
    flashshowDesktop.i.push(...flashExtra);
    // flashshowDesktop.i = flashshowDesktop.i.concat(flashExtra);

    if (options.onlyMobile) {
        //remove d-md-none from mobile
        flashshowMobile.c = flashshowMobile.c.replace("d-md-none", "");
        return flashshowMobile;
    }


    return [
        flashshowMobile,
        flashshowDesktop
    ];

}

/**
 * Check for a random item to set as active.
 */
events.on("interface.afterrender", async function() {
    var uaes = document.querySelectorAll(`uae[namespace='${_.namespace}']`);

    for (var i = 0; i < uaes.length; i++) {
        AnimateShow(uaes[i]);

        // // //do I have a random id in my attribute, if not create one
        // // if (!uaes[i].getAttribute("rnid")) {
        // //     uaes[i].setAttribute("rnid", "rnid" + generateRandomID(32));
        // // }

        // //is there a pause button
        // var pause = uaes[i].querySelector(".home-flashshow-pause");
        // // pauses.setAttribute("rnid", uaes[i].getAttribute("rnid"));
        // pause.addEventListener("click", async (event) => {
        //     //get the click target
        //     var uae = event.target.closest("uae");

        //     console.log("Found uae", uae);

        //     await TogglePause(uae);
        // }, { once: true });

    }

});

document.addEventListener("click", async (event) => {

    var pause = event.target.closest(".home-flashshow-pause");

    if (pause) {
        console.log("Found pause", pause);
        var uae = pause.closest("uae");
        await TogglePause(uae);
    }

});

async function AnimateShow(uae) {
    // var uaes = document.querySelectorAll(`uae[namespace='${_.namespace}']`);

    // for (var i = 0; i < uaes.length; i++) {
    // var uae = uaes[i];

    //ensure it happens once and only once
    if (uae.getAttribute("interface.afterrender")) {
        return;
    }

    var active = Math.floor(Math.random() * uae.querySelectorAll(".home-flashshow-mobile .home-flashshow-item").length);
    active = 0;

    //for mobile version
    AnimateShowHolster(uae, uae.querySelectorAll(".home-flashshow-mobile .home-flashshow-item"), active);

    //for desktop first column
    var col1 = uae.querySelectorAll(".home-flashshow-desktop .home-flashshow-holster-col")[0];
    var col2 = uae.querySelectorAll(".home-flashshow-desktop .home-flashshow-holster-col")[1];

    AnimateShowHolster(uae, col1.querySelectorAll(".home-flashshow-item"), active);



    //for the second column wait half the interval than start
    setTimeout(() => {
        active += 1;
        AnimateShowHolster(uae, col2.querySelectorAll(".home-flashshow-item"), active);
    }, uae.getAttribute("interval") / 2);

    uae.setAttribute("interface.afterrender", true);

}


var intervalz = [];

async function AnimateShowHolster(uae, items, active) {

    //if active is undefined see which one is .active
    if (active === undefined) {
        for (var i = 0; i < items.length; i++) {
            if (items[i].classList.contains("active")) {
                active = i;
                break;
            }
        }
    }

    if (active === undefined) {
        active = 0;
    }

    //clear active from all items
    for (var i = 0; i < items.length; i++) {
        items[i].classList.remove("active");
    }
  
    try {
        items[active].classList.add("active");
    } catch (error) {
        console.log("Looks like there are no items in the holster", active, items);
        items[0].classList.add("active");
    }

    // items[active].classList.add("active");

    var tick = () => {
        items[active].classList.remove("active");
        active = (active + 1) % items.length;
        items[active].classList.add("active");
    };

    var interval = setInterval(tick, uae.getAttribute("interval") || 1000);
    intervalz.push(interval);
    tick();
}

async function TogglePause(uae) {
    var items = uae.querySelectorAll(".home-flashshow-item");
    var active = uae.querySelector(".home-flashshow-item.active");
    var activeNum = Array.prototype.indexOf.call(items, active);

    if (active) {
        active.classList.remove("active");
    }
    
    console.log("Clearing intervals", intervalz.length, intervalz);

    //clear all intervals
    intervalz.forEach((interval) => {
        clearInterval(interval);
    });

    //clear the array
    intervalz = [];

    var pause = uae.querySelector(".home-flashshow-pause i");
    if (pause.classList.contains("bi-pause-circle")) {
        UpdateAllPauseButtons(true);
    } else {
        UpdateAllPauseButtons(false);
        
        //for all holsters
        var holsters = uae.querySelectorAll(".home-flashshow-holster");
        for (var i = 0; i < holsters.length; i++) {
            AnimateShowHolster(uae, holsters[i].querySelectorAll(".home-flashshow-item"));
            await sleep(500);
        }

    }
}

function UpdateAllPauseButtons(pause = false) {
    var pauses = document.querySelectorAll(".home-flashshow-pause");

    for (var i = 0; i < pauses.length; i++) {

        pauses[i].querySelector("i").classList.remove("bi-pause-circle");
        pauses[i].querySelector("i").classList.remove("bi-play-circle");

        if (pause) {
            pauses[i].querySelector("i").classList.add("bi-play-circle");
        } else {
            pauses[i].querySelector("i").classList.add("bi-pause-circle");
        }
    }
}
