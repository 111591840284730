/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
<a target="_blank" href="${href}" class="app">
    <div class="icon-cage bg-blur-white" tabindex="0">
      <i title="${name}" style="background-image:url(${logo})">&nbsp;</i>
    </div>
    <p class="title">Push Past Pain</p>
</a>
*/

var _ = require("../../uam/module.js")(exports, {
    namespace: "ac.app.shortcut",
    title: "An App Shortcut",
    description: "Displays an app shortcut with a logo, title, and link.",
    fields: {
        "name": {
            type: "string",
            description: "The name of the app.",
            required: true
        },
        "domain": {
            type: "string",
            description: "The domain of the app.",
        },
        "roles": {
            type: "array",
            description: "The roles that the app may be granted to use.",
        },
        "logo": {
            type: "uri",
            description: "The logo of the app.",
            required: true
        },
        "login": {
            type: "uri",
            description: "The link to login to the app.",
        },
        "logout": {
            type: "uri",
            description: "The link to logout of the app.",
        },
        "color": {
            text: {
                type: "color",
                description: "The text color of the app preview."
            },
            background: {
                type: "color",
                description: "The background color of the app preview."
            },
            primary: {
                type: "color",
                description: "The primary color of the app preview."
            },
            default: {
                text: "#FFFFFF",
                background: "#222222",
                primary: "#FF0000"
            }
        },
        "description": {
            type: "string",
            description: "The description of the app."
        },
        "expires": {
            type: "date",
            description: "The date and time the app expires."
        },
    }
});

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var interface = require("../../uat/src/interface/interface.js");
var sleep = require("../../uam/functions/sleep.js").function;

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    // var appInner = [{
    //     "n": "div",
    //     "c": "icon-cage bg-blur-75-black test",
    //     "tabindex": "0",
    //     "i": {
    //         "n": "i",
    //         "title": options.name,
    //         "style": `background-image:url(${options.logo})`
    //     }
    // },
    // {
    //     "n": "p",
    //     "c": "title",
    //     "i": options.name
    // }];

    // if (!("href") in options) {
    //     return {
    //         "n": "div",
    //         "c": "app",
    //         "i": appInner
    //     };
    // } else {
    //     return {
    //         "n": "a",
    //         "target": "_blank",
    //         "href": options.href,
    //         "c": "app",
    //         "i": appInner
    //     };
    // }

    //set up defaults for color

    //if I have color but it's null or any of the properties are null, blank or not present fill them in with the defaults
    if (!("color" in options)) {
        options.color = _.define.fields.color.default;
    }

    if (options.color == null) {
        options.color = _.define.fields.color.default;
    }

    if (!("text" in options.color) || options.color.text == null || options.color.text == "") {
        options.color.text = _.define.fields.color.default.text;
    }

    if (!("background" in options.color) || options.color.background == null || options.color.background == "") {
        options.color.background = _.define.fields.color.default.background;
    }

    if (!("primary" in options.color) || options.color.primary == null || options.color.primary == "") {
        options.color.primary = _.define.fields.color.default.primary;
    }


    var app = {
        n: "div",
        c: "app",
        // appid: options.appid,
        s: `--app-text: ${options.color.text}; --app-background: ${options.color.background}; --app-primary: ${options.color.primary};`,
        i: [
            {
                n: "div",
                c: "app-preview",
                i: [
                    {
                        "n": "div",
                        "c": "app-close",
                        "i": {
                            "n": "button",
                            "c": "button btn btn-text-white rounded bg-blur-75-black",
                            "i": {
                                "n": "i",
                                "c": "bi bi-x-circle"
                            }

                        }
                    },
                    {
                        n: "div",
                        c: "app-icon",
                        s: `background-image:url(${options.logo})`,
                        i: ""
                    },
                    {
                        n: "div",
                        c: "app-descriptor",
                        i: [
                            {
                                n: "div",
                                c: "app-name",
                                i: options.name
                            },
                            {
                                n: "div",
                                c: "app-description",
                                i: options.description
                            },
                            {
                                n: "div",
                                c: "app-domain",
                                i: options.domain
                            }
                        ]
                    },
                ]
            }, {
                n: "div",
                c: "app-actions",
                i: [
                    {
                        n: "button",
                        c: "button btn btn-outline-info text-white rounded app-action app-action-login",
                        href: options.login,
                        i: [{
                            n: "i",
                            c: "bi bi-box-arrow-in-right",
                        }, " visit"]
                    }, {
                        n: "a",
                        target: "_blank",
                        c: "button btn btn-text-info text-white rounded app-action app-action-logout",
                        href: options.logout,
                        i: [{
                            n: "i",
                            c: "bi bi-door"
                        }, " logout"]
                    }
                ]
            }, {
                n: "div",
                c: "app-details",
                i: [
                {
                        n: "div",
                        c: "app-reciepts",
                        i: [
                            {
                                n: "ua.header",
                                icon: "bi-receipt-cutoff",
                                title: "Reciepts"
                            }, 
                            {
                                n: "p",
                                c: "alert alert-info",
                                i: "Check back later, we're still working on this item."
                            }
                        ]
                    
                } ,{
                    n: "div",
                    c: "app-subscriptions",
                    i: [
                        {
                            n: "ua.header",
                            icon: "bi-receipt-cutoff",
                            title: "Subscriptions"
                        }, 
                        {
                            n: "p",
                            c: "alert alert-info",
                            i: "Check back later, we're still working on this item."
                        }
                    ]
                }
                ]
            }
        ]
    };

    //         "n": "a",
    //         "target": "_blank",
    //         "href": options.href,
    //         "c": "border-0 bg-75-black bg-100-black-hover rounded tiny-outline m-3 row ",
    //         "i": [
    //             {
    //                 "n": "div",
    //                 "c": "col-4 p-3",
    //                 "i": {
    //                     "n": "img",
    //                     "src": options.logo,
    //                     "alt": options.name,
    //                     "class": "image-fluid border-0 outline-0",
    //                     "style": "max-height: 4rem;"
    //                 }
    //             },
    //         {
    //             "n": "div",
    //             "c": "col",
    //             "i": {
    //                 "n": "p",
    //                 "c": "title p-3 text-white fs-4 text-start",
    //                 "i": options.name
    //             }
    //         }
    //       ]
    //     }
    // };

    app = await jsonRender.render(app);

    var login = app.querySelector(".app-action-login");
    var navigating = false;

    var hideApp = async () => {
        app.classList.remove("expanded");
        document.body.classList.remove("modal-open");

        //If I'm safari call refresh()
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            // console.log("Safari");
            if (!(navigating)) {
                window.location.reload();
            }
        } 

        //add the event listener back
        app.addEventListener("click", showApp, {
            once: true
        });

        navigating = false;
    };
    
    var nGoTo = (appid) => {
        navigating = true;
        var nWin =  window.open('', '_blank');
        GoTo(nWin, options.appid);
        //remove expanded
        hideApp();
    };
    
    var showApp = async () => {

        //if it's not already expanded
        if (!(app.classList.contains("expanded"))) {
            //remove the expanded class from all apps
            var apps = document.querySelectorAll(".app.expanded");
            for (var i = 0; i < apps.length; i++) {
                apps[i].classList.remove("expanded");
            }

            // console.log("showing app");
            app.classList.add("expanded");
            document.body.classList.add("modal-open");
        }

        login.addEventListener("click", nGoTo, {
            once: true
        });


         // add the app close
        var appClose = app.querySelector(".app-close button");
        appClose.addEventListener("click", async () => {
            document.body.classList.remove("modal-open");

            //clear the login event listener
            login.removeEventListener("click", nGoTo, {
                once: true
            });

            await sleep(.5);

            await hideApp(); 
            
        }, {
            once: true
        });

    };


    // add the class "expanded" to the app if it's been clicked
    app.addEventListener("click", showApp, {
        once: true
    });


    // console.log("App", options);

    return app;

}
// /**
//  * Saves the code from the iframe.
//  * Called by UATools.
//  * @param {*} name The name of the control.
//  * @param {*} control The control to save.
//  * @param {*} repo The repo (used to send back), with the prevalidation results.
//  * @property {*} repo.success The success object (is this field ready to be saved).
//  * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
//  * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
//  * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
//  * @property {*} repo.errors.input.id The id of the input field, if applicable.
//  * @property {*} repo.errors.input.name The name of the input field if applicable.
//  * @property {*} repo.errors.type The type of error that occured.
//  *  - Supports: "validation" - The input or field or control is invalid
//  *  - Supports: "thowable" - Processing this field caused a throwable to error out.
//  * @property {*} repo.errors.message The message to display to the user.
//  * @returns The repo object with the data to save
//  */
// async function save(name, control, repo) {

//   // console.log("Trying save", {
//   //   name, control, repo
//   // });

//   //get the span
//   var span = control.querySelector("span");
//   repo.data = span.innerText;
//   return repo;

// } module.exports.save = save;

// /**
//  * Loads the control with data.
//  * @param {*} name The name of the control.
//  * @param {*} control The control itself (including placeholder tag).
//  * @param {*} data The data to load into the control.
//  */
// async function load(name, control, data) {

//   //get the span
//   var span = control.querySelector("span");
//   span.innerText = data;

// } module.exports.load = load;