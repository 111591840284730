/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "pages.meta";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");

var required = ["inner"];
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Pages: Opengraph SEO Metatags",
  description: "Adds the opengraph SEO metatags to the page. This supports sharing on social media and helping users find your page.",
  fields: {
    "title": {
      type: "string", 
      description: "The title of the page.",
      required: true
    }, 
    "description": {
      type: "string", 
      description: "The description of the page.",
      required: true
    },
    "image": {
      type: "string", 
      description: "The featured image of the page."
    },
    "video": {
      "url": {
        type: "string", 
        description: "The url of the video.",
        required: true
      },
      "secure_url": {
        type: "string", 
        description: "The secure url of the video.",
        required: true
      },
      "type": {
        type: "string", 
        description: "The type of the video.",
        required: true
      },
      "width": {
        type: "string", 
        description: "The width of the video.",
        required: true
      },
      "height": {
        type: "string", 
        description: "The height of the video.",
        required: true
      }, 
      "image": {
        type: "string", 
        description: "The image of the video."
      }
    },
    "audio": {
      type: "string",
      description: "The audio of the page.",
    },
    "url": {
      type: "string", 
      description: "The url of the page.",
    },
    // "keywords": {
    //   type: "string", 
    //   description: "The keywords of the page.",
    //   required: true
    // },
    "author": {
      type: "string", 
      description: "The author of the page."//,
      // required: true
    },
    "universe": {
      "appid": {
        type: "string", 
        description: "The appid of the page.",
        required: true
      },
      "namespace": {
        type: "string", 
        description: "The appid of the page.",
        required: true
      },
      "author": {
        type: "string", 
        description: "The author of the page.",
      }
    },
    "isArticle": {
      type: "boolean",
      description: "Is this page an article?",
      default: false
    },
    "sitename" : {
      type: "string",
      description: "The name of the site."//,
      // required: true
    }, "on":{
      "published": {
      type: "date",
      description: "The date the page was published."
      }, 
      "modified": {
        type: "date",
        description: "The date the page was modified."
      }
    },
    "social": {
      "_GroupOf": {
        "twitter": {
          type: "string",
          description: "The twitter username for the page.",
        },
        "facebook": {
          type: "string",
          description: "The facebook username for the page.",
        },
        "instagram": {
          type: "string",
          description: "The instagram username for the page.",
        },
        "youtube": {
          type: "string",
          description: "The youtube username for the page.",
        },
        "linkedin": {
          type: "string",
          description: "The linkedin username for the page.",
        },
        "pinterest": {
          type: "string",
          description: "The pinterest username for the page.",
        },
        "tumblr": {
          type: "string",
          description: "The tumblr username for the page.",
        },
        "snapchat": {
          type: "string",
          description: "The snapchat username for the page.",
        },
        "reddit": {
          type: "string",
          description: "The reddit username for the page.",
        },
        "vimeo": {
          type: "string",
          description: "The vimeo username for the page.",
        },
        "soundcloud": {
          type: "string",
          description: "The soundcloud username for the page.",
        },
        "spotify": {
          type: "string",
          description: "The spotify username for the page.",
        },
        "twitch": {
          type: "string",
          description: "The twitch username for the page.",
        },
        "mixer": {
          type: "string",
          description: "The mixer username for the page.",
        },
        "github": {
          type: "string",
          description: "The github username for the page.",
        },
        "gitlab": {
          type: "string",
          description: "The gitlab username for the page.",
        },
        "discord": {
          type: "string",
          description: "The discord username for the page.",
        },
        "cashapp": {
          type: "string",
          description: "The cashapp username for the page.",
        }
      }, 
      "app": {
        type: "_GroupOf",
        description: "The soical handles for the app.",
      }, 
      "user": {
        type: "_GroupOf",
        description: "The social handles for the user.",
      }
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The rendered DOM object.
 */
exports.render = function (json) {

  // var options = uae.GetOptions(json, required);

  var metaTags = [];

  if ("title" in json) {
    metaTags.push({
      "n": "title",
      "c": json.title
    }, {
      "n": "og:title",
      "c": json.title
    }, {
      "n": "twitter:title",
      "c": json.title
    });

    document.title = json.title;

  }

  if ("description" in json) {
    metaTags.push({
      "n": "description",
      "c": json.description
    }, {
      "n": "og:description",
      "c": json.description
    }, {
      "n": "twitter:description",
      "c": json.description
    });
  }

  // if ("keywords" in json) {
  //   metaTags.push({
  //     "n": "keywords",
  //     "c": json.keywords
  //   });
  // }

  if ("url" in json) {
    metaTags.push({
      "n": "og:url",
      "c": json.url
    });
  }


  //I'm an article type
  if ("isArticle" in json && json.isArticle) {
    metaTags.push({
      "n": "og:type",
      "c": "article"
    });
  } else {
    if ("type" in json) {
      metaTags.push({
        "n": "og:type",
        "c": json.type
      });
    } else {
      metaTags.push({
        "n": "og:type",
        "c": "website"
      });
    }
  }

  if ("sitename" in json) {
    metaTags.push({
      "n": "og:site_name",
      "c": json.sitename
    });
  }

  //create all the required meta tags
  metaTags.push({
    "n": "twitter:card",
    "c": "summary_large_image"
  });
  
  //for every options.social.author
  if ("social" in json) {
    if ("author" in json.social) {
      for (var social in json.social.author) {
        metaTags.push({
          "n": `social:author:${social}`,
          "c": json.social.author[social]
        });

        if (social == "twitter") {
          metaTags.push({
            "n": "twitter:creator",
            "c": json.social.author[social]
          });
        }

      }
    } else if ("app" in json.social) {
      for (var social in json.social.author) {
        metaTags.push({
          "n": `social:app:${social}`,
          "c": json.social.app[social]
        });

        if (social == "twitter") {
          metaTags.push({
            "n": "twitter:site",
            "c": json.social.app[social]
          });
        }
      }
    }
  }

  // if ("twitter" in json) {
  //   if ("site" in json.twitter) {
  //     metaTags.push({
  //       "n": "twitter:site",
  //       "c": json.twitter.site
  //     });
  //   }
  // }


  //if json has a video
  if ("video" in json) {
    //change the type to video
    metaTags.find(x => x.n == "og:type").c = "video";

    //add the video tags
    metaTags.push({
      "n": "og:video",
      "c": json.video.url
    }, {
      "n": "og:video:type",
      "c": json.video.type
    }, {
      "n": "og:video:width",
      "c": json.video.width
    }, {
      "n": "og:video:height",
      "c": json.video.height
    }, {
      "n": "og:video:secure_url",
      "c": json.video.secure_url
    });

    //if json has a video image
    if ("image" in json.video) {
      metaTags.push({
        "n": "og:image",
        "c": json.video.image
      });
      metaTags.push({
        "n": "twitter:image",
        "c": json.video.image
      });
    }

  };

  //if metaTags doesn't already have an image
  if ("image" in json) {
    if (!metaTags.find(x => x.n == "og:image")) {
      //if json has an image

        metaTags.push({
          "n": "og:image",
          "c": json.image
        });

        metaTags.push({
          "n": "twitter:image",
          "c": json.image
        });
    }
  }

  //if json has an audio
  if ("audio" in json) {
    metaTags.push({
      "n": "og:audio",
      "c": json.audio
    });

    //change type
    metaTags.find(x => x.n == "og:type").c = "audio";

  }

  //if I'm an article
  if ("isArticle" in json && json.isArticle) {
    //change type
    metaTags.find(x => x.n == "og:type").c = "article";

  }

  //Do I have date information?
  if ("on" in json) {
    //if I have a published date
    if ("published" in json.on) {
      metaTags.push({
        "n": "article:published_time",
        "c": json.on.published
      });
    }

    //if I have a modified date
    if ("modified" in json.on) {
      metaTags.push({
        "n": "article:modified_time",
        "c": json.on.modified
      });
    }
  }

  SetMetaTags(metaTags);

  //I'm returning nothing, because I'm a meta tag, and I don't render anything.
  var newDocumentFragment = document.createDocumentFragment();
  return newDocumentFragment;
}



/**
 * Set the meta tags based on the meta tag object
 * @param {array} metaTags The meta tag object
 * @property {string} metaTags.n The name of the meta tag
 * @property {string} metaTags.c The content of the meta tag
 */
function SetMetaTags(metaTags) {

  //set meta tags for an object [{n:"tag name", c:"tag content"}, ...]

  //add the new meta tags
  for (var i = 0; i < metaTags.length; i++) {
    var metaTag = document.createElement("meta");
    metaTag.setAttribute("name", metaTags[i].n);
    metaTag.setAttribute("content", metaTags[i].c);
    document.getElementsByTagName("head")[0].appendChild(metaTag);
  }

}
/**
 * 
 * List of meta tags
 * 
 * 
 <title></title>
 <meta name="title" content="Title of the page">
 <meta name="description" content="Description of the page">
 <meta name="keywords" content="keywords for search engines">

 * Open graph
 <meta property="og:title" content="Your Title">
 <meta property="og:type" content="website">
 <meta property="og:url" content="URL of the content">
 <meta property="og:description" content="Description of the content">
 <meta property="og:site_name" content="Your Site Name">

 * Twitter Support
 <meta name="twitter:card" content="summary_large_image">
 <meta name="twitter:title" content="Your title">
 <meta name="twitter:description" content="Your description">
 <meta name="twitter:image" content="URL to your featured image">
 <meta name="twitter:site" content="@yourtwitterhandle"> //side social media
 <meta name="twitter:creator" content="@authorhandle"> //author social media

 * Author
<meta property="article:author" content="Author's Name">
<meta property="author" content="Author's Name">

 * If the type is article
<meta property="og:type" content="article">

* If the type is website
<meta property="og:type" content="website">

 * If On has a published date/time
<meta property="article:published_time" content="Publication Date">

 * If On has a modified date/time
<meta property="article:modified_time" content="Last Modified Date">

 * If you have a video
<meta property="og:video" content="URL to video">
<meta property="og:video:type" content="video/mp4">
<meta property="og:video:width" content="Width in pixels">
<meta property="og:video:height" content="Height in pixels">
<meta property="og:video:secure_url" content="Secure URL to video">
<meta property="og:type" content="video">

 *If you have audio
<meta property="og:audio" content="URL to audio">

 * Social groups for (app, user)
<meta property="social:app:twitter" content="Twitter handler">
<meta property="social:app:facebook" content="Facebook handler">
<meta property="social:app:instagram" content="Instagram handler">
<meta property="social:app:youtube" content="Youtube handler">
<meta property="social:app:linkedin" content="LinkedIn handler">
<meta property="social:app:pinterest" content="Pinterest handler">
<meta property="social:app:tumblr" content="Tumblr handler">
<meta property="social:app:snapchat" content="Snapchat handler">
<meta property="social:app:reddit" content="Reddit handler">
<meta property="social:app:vimeo" content="Vimeo handler">
<meta property="social:app:soundcloud" content="Soundcloud handler">
<meta property="social:app:spotify" content="Spotify handler">
<meta property="social:app:twitch" content="Twitch handler">
<meta property="social:app:mixer" content="Mixer handler">
<meta property="social:app:github" content="Github handler">
<meta property="social:app:gitlab" content="Gitlab handler">
<meta property="social:app:discord" content="Discord handler">
 * Social groups for user
<meta property="social:user:twitter" content="Twitter handler">
<meta property="social:user:facebook" content="Facebook handler">
<meta property="social:user:instagram" content="Instagram handler">
<meta property="social:user:youtube" content="Youtube handler">
<meta property="social:user:linkedin" content="LinkedIn handler">
<meta property="social:user:pinterest" content="Pinterest handler">
<meta property="social:user:tumblr" content="Tumblr handler">
<meta property="social:user:snapchat" content="Snapchat handler">
<meta property="social:user:reddit" content="Reddit handler">
<meta property="social:user:vimeo" content="Vimeo handler">
<meta property="social:user:soundcloud" content="Soundcloud handler">
<meta property="social:user:spotify" content="Spotify handler">
<meta property="social:user:twitch" content="Twitch handler">
<meta property="social:user:mixer" content="Mixer handler">
<meta property="social:user:github" content="Github handler">
<meta property="social:user:gitlab" content="Gitlab handler">
<meta property="social:user:discord" content="Discord handler">
 * 
 */
